.footer-footer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.footer-footer1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}
.footer-container {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.footer-logo {
  color: var(--dl-color-gray-white);
  font-size: 2em;
  font-weight: bold;
}
.footer-nav {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.footer-nav1 {
  color: var(--dl-color-secondary-gray500);
  margin-left: 0px;
  text-decoration: none;
}
.footer-nav2 {
  color: var(--dl-color-secondary-gray500);
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-nav3 {
  color: var(--dl-color-secondary-gray500);
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-nav4 {
  color: var(--dl-color-secondary-gray500);
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-nav5 {
  color: var(--dl-color-secondary-gray500);
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.footer-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-800);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.footer-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.footer-text {
  color: var(--dl-color-secondary-gray500);
}
.footer-icon-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.footer-link {
  display: contents;
}
.footer-image {
  width: 30px;
  object-fit: cover;
  margin-left: var(--dl-space-space-twounits);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.footer-link1 {
  display: contents;
}
.footer-icon {
  fill: #ffffff;
  width: 24px;
  height: auto;
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}





@media(max-width: 767px) {
  .footer-footer1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .footer-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .footer-container1 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .footer-text {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .footer-footer1 {
    padding: var(--dl-space-space-unit);
  }
  .footer-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .footer-container1 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .footer-text {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
