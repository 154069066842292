.account-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.account-container1 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
}
.account-container2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.account-image {
  width: 200px;
  object-fit: cover;
}
.account-container3 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.account-image1 {
  width: 200px;
  object-fit: cover;
}
