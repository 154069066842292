.articles-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.articles-hero {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: -50px;
  padding-right: -50px;
  flex-direction: column;
  padding-bottom: -50px;
  background-size: cover;
  justify-content: flex-start;
  background-image: url("/Images/ucsd%20bridge1-1500w.png");
}
.articles-hero1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  min-height: auto;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.articles-container01 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.articles-hero-heading {
  color: var(--dl-color-gray-white);
  max-width: 800px;
  text-align: center;
  font-family: "Raleway";
  line-height: 1.6;
}
.articles-hero-sub-heading {
  color: var(--dl-color-gray-white);
  font-size: 18px;
  text-align: center;
  line-height: 1.6;
}
.articles-btn-group {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.articles-hero-button1 {
  color: var(--dl-color-gray-white);
  font-size: 18px;
  transition: 0.3s;
  font-weight: bold;
  padding-top: 1.5rem;
  border-color: var(--dl-color-primary1-blue100);
  border-width: 0px;
  padding-left: 3rem;
  border-radius: 45px;
  padding-right: 3rem;
  padding-bottom: 1.5rem;
  text-decoration: none;
  background-color: #000000;
}
.articles-hero-button1:hover {
  color: var(--dl-color-gray-white);
  border-color: rgba(41, 20, 119, 0.9);
  background-color: rgba(41, 20, 119, 0.9);
}
.articles-details {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #D9D9D9;
}
.articles-details1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}
.articles-container02 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.articles-text02 {
  color: var(--dl-color-primary1-blue80);
  text-align: left;
  font-family: "Raleway";
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.articles-details-heading {
  width: 80%;
  text-align: left;
  font-family: "Raleway";
  line-height: 1.6;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.articles-details-sub-heading {
  color: var(--dl-color-gray-800);
  font-size: 18px;
  text-align: left;
  line-height: 1.6;
  margin-bottom: var(--dl-space-space-twounits);
}
.articles-details-image {
  width: 400px;
  height: 400px;
  object-fit: cover;
  margin-left: var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-round);
}
.articles-features {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.articles-features-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-secondary-grey400);
}
.articles-features1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: flex-start;
}
.articles-container03 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.articles-text05 {
  color: var(--dl-color-primary1-blue80);
  font-family: "Raleway";
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.articles-features-heading {
  font-family: "Raleway";
  line-height: 1.6;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.articles-features-sub-heading {
  color: var(--dl-color-gray-800);
  font-size: 18px;
  text-align: center;
  line-height: 1.6;
}
.articles-container04 {
  width: 100%;
  display: none;
  grid-gap: var(--dl-space-space-twounits);
  grid-template-columns: 1fr 1fr;
}
.articles-feature-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
}
.articles-icon {
  fill: var(--dl-color-primary1-blue80);
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  margin-right: var(--dl-space-space-oneandhalfunits);
}
.articles-container05 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.articles-container06 {
  gap: var(--dl-space-space-unit);
  width: 1205px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.articles-text08 {
  font-family: "Raleway";
  font-weight: 600;
  line-height: 28px;
}
.articles-text09 {
  color: var(--dl-color-gray-800);
  font-size: 16px;
  font-family: "Noto Sans";
  font-weight: 400;
  line-height: 1.6;
  text-transform: none;
  text-decoration: none;
}
.articles-text18 {
  font-weight: 700;
}
.articles-image {
  width: 650px;
  align-self: center;
  object-fit: cover;
}
.articles-text20 {
  color: var(--dl-color-gray-800);
  font-family: "Noto Sans";
  line-height: 1.6;
}
.articles-text28 {
  font-weight: 700;
}
.articles-image1 {
  width: 650px;
  align-self: center;
  object-fit: cover;
}
.articles-text31 {
  color: var(--dl-color-gray-800);
  font-size: 16px;
  font-family: "Noto Sans";
  font-weight: 400;
  line-height: 1.6;
  text-transform: none;
  text-decoration: none;
}
.articles-container07 {
  align-self: center;
}
.articles-container08 {
  display: contents;
}
.articles-text34 {
  color: var(--dl-color-gray-800);
  font-size: 16px;
  font-family: "Noto Sans";
  font-weight: 400;
  line-height: 1.6;
  text-transform: none;
  text-decoration: none;
}
.articles-feature-card1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
}
.articles-icon2 {
  fill: var(--dl-color-primary1-blue80);
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  margin-right: var(--dl-space-space-oneandhalfunits);
}
.articles-container09 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.articles-text37 {
  font-family: "Raleway";
  font-weight: 600;
  line-height: 28px;
}
.articles-text38 {
  color: var(--dl-color-gray-800);
  line-height: 1.6;
}
.articles-video {
  width: 500px;
  height: auto;
  align-self: center;
}
.articles-text41 {
  color: var(--dl-color-gray-800);
  line-height: 1.6;
}
.articles-feature-card2 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
}
.articles-icon4 {
  fill: var(--dl-color-primary1-blue80);
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  margin-right: var(--dl-space-space-oneandhalfunits);
}
.articles-container10 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.articles-text45 {
  font-family: "Raleway";
  font-weight: 600;
  line-height: 28px;
}
.articles-text46 {
  color: var(--dl-color-gray-800);
  line-height: 1.6;
}
.articles-text51 {
  font-weight: 700;
}
.articles-text58 {
  font-weight: 700;
}
.articles-text65 {
  font-weight: 700;
}
.articles-text71 {
  font-weight: 700;
}
.articles-footer {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
@media(max-width: 991px) {
  .articles-hero1 {
    flex-direction: column;
  }
  .articles-container01 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .articles-hero-heading {
    text-align: center;
  }
  .articles-text {
    text-align: center;
  }
  .articles-hero-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .articles-details-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .articles-features-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .articles-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .articles-hero-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .articles-details1 {
    align-items: center;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: center;
  }
  .articles-container02 {
    align-items: center;
    justify-content: flex-start;
  }
  .articles-text02 {
    text-align: center;
  }
  .articles-details-heading {
    text-align: center;
  }
  .articles-details-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .articles-details-image {
    margin-top: var(--dl-space-space-threeunits);
    margin-left: 0px;
  }
  .articles-features1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .articles-features-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .articles-container04 {
    grid-template-columns: 1fr;
  }
  .articles-feature-card {
    flex-direction: row;
  }
  .articles-container06 {
    flex-direction: column;
  }
  .articles-feature-card1 {
    flex-direction: row;
  }
  .articles-container09 {
    flex-direction: column;
  }
  .articles-feature-card2 {
    flex-direction: row;
  }
  .articles-container10 {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .articles-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .articles-container01 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .articles-btn-group {
    flex-direction: column;
  }
  .articles-features1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .articles-container06 {
    flex-direction: column;
  }
  .articles-container09 {
    flex-direction: column;
  }
  .articles-container10 {
    flex-direction: column;
  }
}
