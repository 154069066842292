.the-solid-forge-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.the-solid-forge-container1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 100%;
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
  background-color: #000000;
}
.the-solid-forge-logo {
  color: var(--dl-color-gray-white);
  font-size: 2em;
  font-weight: bold;
}
.the-solid-forge-container2 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.the-solid-forge-netlify-container {
  width: var(--dl-size-size-large);
  margin: var(--dl-space-space-unit);
}
.the-solid-forge-netlify-button {
  flex: 0 0 auto;
  color: white;
  width: auto;
  height: auto;
  margin: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-style: solid;
  border-width: 2px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.the-solid-forge-text {
  color: var(--dl-color-gray-black);
  display: none;
}
.the-solid-forge-text1 {
  color: var(--dl-color-gray-black);
}
.the-solid-forge-container3 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.the-solid-forge-nav {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.the-solid-forge-nav1 {
  color: var(--dl-color-secondary-gray500);
  margin-left: 0px;
  text-decoration: none;
}
.the-solid-forge-nav2 {
  color: var(--dl-color-secondary-gray500);
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.the-solid-forge-nav3 {
  color: var(--dl-color-secondary-gray500);
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.the-solid-forge-nav4 {
  color: var(--dl-color-secondary-gray500);
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.the-solid-forge-nav5 {
  color: var(--dl-color-secondary-gray500);
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.the-solid-forge-image {
  width: 100%;
  padding: -8px;
  object-fit: cover;
}
@media(max-width: 1600px) {
  .the-solid-forge-netlify-button {
    padding: var(--dl-space-space-unit);
    align-items: center;
    border-color: var(--dl-color-secondary-gray500);
    border-width: 1px;
    justify-content: center;
  }
}
@media(max-width: 767px) {
  .the-solid-forge-netlify-button {
    padding: var(--dl-space-space-unit);
    align-items: center;
    border-color: var(--dl-color-gray-black);
    border-width: 1px;
    padding-bottom: 16px;
    justify-content: center;
  }
}
