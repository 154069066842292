.contact-and-about-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.contact-and-about-banner {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  background-size: cover;
  justify-content: space-between;
  background-image: url('https://images.unsplash.com/photo-1485628390555-1a7bd503f9fe?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE3fHxhcmNoaXRlY3R1cmUlMjBtaW5pbWFsfGVufDB8fHx8MTYyNjE4NDA0Mg&ixlib=rb-1.2.1&w=1500');
  background-position: center;
}
.contact-and-about-text {
  font-size: 0.75rem;
  text-align: center;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-twounits);
  letter-spacing: 2px;
  text-transform: uppercase;
}
.contact-and-about-text01 {
  max-width: var(--dl-size-size-maxwidth);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.contact-and-about-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.contact-and-about-button {
  color: var(--dl-color-gray-white);
  width: 300px;
  height: 100px;
  font-size: 0.75rem;
  text-align: center;
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  background-color: var(--dl-color-gray-black);
}
.contact-and-about-button:hover {
  color: var(--dl-color-gray-black);
  background-color: transparent;
}
.contact-and-about-text08 {
  font-size: 1rem;
}
.contact-and-about-button1 {
  color: var(--dl-color-gray-white);
  width: 300px;
  height: 100px;
  font-size: 0.75rem;
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  background-color: var(--dl-color-gray-black);
}
.contact-and-about-button1:hover {
  color: var(--dl-color-gray-black);
  background-color: transparent;
}
.contact-and-about-text12 {
  font-size: 1rem;
  text-align: center;
}
.contact-and-about-details {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-and-about-details1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
}
.contact-and-about-container2 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.contact-and-about-details-heading {
  width: 80%;
  text-align: left;
  font-family: "Raleway";
  line-height: 1.6;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.contact-and-about-details-sub-heading {
  color: var(--dl-color-gray-800);
  font-size: 18px;
  text-align: left;
  line-height: 1.6;
  margin-bottom: var(--dl-space-space-twounits);
}
.contact-and-about-details-image {
  width: 455px;
  height: 556px;
  object-fit: cover;
  margin-left: var(--dl-space-space-fourunits);
  border-radius: var(--dl-radius-radius-round);
}
.contact-and-about-faq {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact-and-about-faq-container {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dl-color-secondary-grey400);
}
.contact-and-about-faq1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fourunits);
}
.contact-and-about-container3 {
  width: 411px;
  display: flex;
  max-width: 35%;
  align-self: stretch;
  align-items: flex-start;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: flex-start;
  background-color: #D9D9D9;
}
.contact-and-about-text16 {
  align-self: center;
  font-family: "Raleway";
  line-height: 1.6;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.contact-and-about-details-image1 {
  width: 346px;
  height: 383px;
  margin-top: var(--dl-space-space-twounits);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
  margin-bottom: var(--dl-space-space-twounits);
}
.contact-and-about-container4 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 642px;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: flex-start;
  flex-direction: column;
  background-color: #D9D9D9;
}
@media(max-width: 991px) {
  .contact-and-about-text01 {
    width: 100%;
  }
  .contact-and-about-details-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .contact-and-about-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .contact-and-about-text01 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .contact-and-about-details1 {
    align-items: center;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: center;
  }
  .contact-and-about-container2 {
    align-items: center;
    justify-content: flex-start;
  }
  .contact-and-about-details-heading {
    text-align: center;
  }
  .contact-and-about-details-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .contact-and-about-details-image {
    margin-top: var(--dl-space-space-threeunits);
    margin-left: 0px;
  }
  .contact-and-about-faq1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .contact-and-about-container3 {
    max-width: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .contact-and-about-details-image1 {
    margin-top: var(--dl-space-space-threeunits);
    margin-left: 0px;
  }
}
@media(max-width: 479px) {
  .contact-and-about-banner {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .contact-and-about-container1 {
    align-items: center;
    flex-direction: column;
  }
  .contact-and-about-button {
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
  }
  .contact-and-about-button1 {
    margin-top: var(--dl-space-space-unit);
    margin-left: 0px;
  }
}
