.navbar-interactive-container {
  display: flex;
  position: relative;
}
.navbar-interactive-navbar-interactive {
  width: auto;
  display: flex;
  position: sticky;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 50px;
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
}
.navbar-interactive-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.navbar-interactive-links {
  flex: 1;
  display: flex;
  align-self: center;
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  padding-top: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.navbar-interactive-nav1 {
  width: var(--dl-size-size-large);
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-interactive-nav1:hover {
  color: #3d6e70ff;
}
.navbar-interactive-nav11 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-interactive-nav11:hover {
  color: #3d6e70ff;
}
.navbar-interactive-nav2 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-interactive-nav2:hover {
  color: #3d6e70ff;
}
.navbar-interactive-nav3 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-interactive-nav3:hover {
  color: #3d6e70ff;
}
.navbar-interactive-nav4 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-interactive-nav4:hover {
  color: #3d6e70ff;
}
.navbar-interactive-nav5 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-interactive-nav5:hover {
  color: #3d6e70ff;
}
.navbar-interactive-buttons {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.navbar-interactive-login {
  border-color: var(--dl-color-primary1-blue100);
  border-width: 1px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: 1.5rem;
  border-radius: 45px;
  padding-right: 1.5rem;
}
.navbar-interactive-register {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  font-weight: bold;
  border-color: var(--dl-color-primary1-blue100);
  padding-left: 1.5rem;
  border-radius: 45px;
  padding-right: 1.5rem;
  background-color: var(--dl-color-primary1-blue100);
}
.navbar-interactive-register:hover {
  border-color: rgba(41, 20, 119, 0.9);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background-color: rgba(41, 20, 119, 0.9);
}
.navbar-interactive-burger-menu {
  display: none;
}
.navbar-interactive-icon {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.navbar-interactive-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  border-radius: 50px;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.navbar-interactive-links1 {
  flex: 1;
  display: flex;
  align-self: center;
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  padding-top: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.navbar-interactive-nav12 {
  width: var(--dl-size-size-large);
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-interactive-nav12:hover {
  color: #3d6e70ff;
}
.navbar-interactive-nav13 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-interactive-nav13:hover {
  color: #3d6e70ff;
}
.navbar-interactive-nav21 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-interactive-nav21:hover {
  color: #3d6e70ff;
}
.navbar-interactive-nav31 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-interactive-nav31:hover {
  color: #3d6e70ff;
}
.navbar-interactive-nav41 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-interactive-nav41:hover {
  color: #3d6e70ff;
}
.navbar-interactive-nav51 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-interactive-nav51:hover {
  color: #3d6e70ff;
}
.navbar-interactive-buttons1 {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.navbar-interactive-login1 {
  border-color: var(--dl-color-primary1-blue100);
  border-width: 1px;
  margin-right: var(--dl-space-space-twounits);
  padding-left: 1.5rem;
  border-radius: 45px;
  padding-right: 1.5rem;
}
.navbar-interactive-register1 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  font-weight: bold;
  border-color: var(--dl-color-primary1-blue100);
  padding-left: 1.5rem;
  border-radius: 45px;
  padding-right: 1.5rem;
  background-color: var(--dl-color-primary1-blue100);
}
.navbar-interactive-register1:hover {
  border-color: rgba(41, 20, 119, 0.9);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  background-color: rgba(41, 20, 119, 0.9);
}

.navbar-interactive-root-class-name1 {
  align-self: center;
}




.navbar-interactive-root-class-name6 {
  margin: var(--dl-space-space-halfunit);
  display: none;
}
@media(max-width: 767px) {
  .navbar-interactive-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .navbar-interactive-burger-menu {
    align-items: center;
    justify-content: center;
  }
  .navbar-interactive-mobile-menu {
    padding-top: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .navbar-interactive-navbar-interactive {
    width: auto;
    padding: var(--dl-space-space-unit);
    max-width: auto;
  }
  .navbar-interactive-desktop-menu {
    display: none;
  }
  .navbar-interactive-burger-menu {
    display: flex;
  }
  .navbar-interactive-icon {
    fill: #D9D9D9;
  }
  .navbar-interactive-mobile-menu {
    flex: 1;
    width: auto;
    height: auto;
    padding: 16px;
    position: static;
  }
  .navbar-interactive-links1 {
    flex-direction: column;
  }
  .navbar-interactive-nav12 {
    align-self: center;
    font-style: normal;
    margin-top: 4px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 4px;
  }
  .navbar-interactive-nav13 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .navbar-interactive-nav21 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .navbar-interactive-nav31 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .navbar-interactive-nav41 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .navbar-interactive-nav51 {
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .navbar-interactive-buttons1 {
    display: flex;
  }
  .navbar-interactive-login1 {
    margin-left: var(--dl-space-space-unit);
    border-color: var(--dl-color-gray-black);
    border-width: 2px;
  }
  .navbar-interactive-register1 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 400;
    border-color: var(--dl-color-gray-black);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    background-color: var(--dl-color-gray-white);
  }
  .navbar-interactive-root-class-name6 {
    width: auto;
  }
}
