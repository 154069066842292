.profile-and-resume-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.profile-and-resume-header {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.profile-and-resume-experience {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-and-resume-pricing {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  background-color: var(--dl-color-gray-white);
}
.profile-and-resume-container01 {
  gap: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.profile-and-resume-pricing-heading {
  font-family: "Raleway";
  line-height: 1.6;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.profile-and-resume-pricing-sub-heading {
  color: var(--dl-color-gray-800);
  font-size: 18px;
  text-align: center;
  line-height: 1.6;
}
.profile-and-resume-container02 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.profile-and-resume-nc-state-experience {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.profile-and-resume-image {
  top: 0px;
  left: 0px;
  right: auto;
  width: 100%;
  bottom: auto;
  height: 483px;
  position: absolute;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
.profile-and-resume-feature-card {
  width: 95%;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  position: relative;
  align-self: center;
  margin-top: 300px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
}
.profile-and-resume-image1 {
  width: 4rem;
  height: 4rem;
  margin: var(--dl-space-space-unit);
  object-fit: cover;
}
.profile-and-resume-container03 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.profile-and-resume-text {
  font-family: "Raleway";
  font-weight: 600;
  line-height: 28px;
}
.profile-and-resume-text001 {
  color: var(--dl-color-gray-800);
  line-height: 1.6;
}
.profile-and-resume-banner {
  width: 100%;
  height: 724px;
  display: flex;
  margin-top: -50px;
  align-items: center;
  justify-content: center;
  background-color: var(--dl-color-gray-black);
}
.profile-and-resume-profesional-background {
  gap: var(--dl-space-space-oneandhalfunits);
  width: var(--dl-size-size-maxwidth);
  height: 774px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-fiveunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 325px;
  background-size: cover;
  justify-content: flex-start;
  background-image: url("/Images/ucsd%20spirit%20gate-1400w.png");
}
.profile-and-resume-banner-heading {
  color: var(--dl-color-gray-white);
  text-align: center;
  font-family: "Raleway";
  line-height: 1.6;
}
.profile-and-resume-banner-sub-heading {
  color: var(--dl-color-gray-white);
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  line-height: 1.6;
  margin-bottom: var(--dl-space-space-twounits);
}
.profile-and-resume-banner-button {
  color: var(--dl-color-gray-black);
  margin-top: var(--dl-space-space-sixunits);
  transition: 0.3s;
  font-weight: 700;
  padding-top: 1.5rem;
  border-width: 0px;
  padding-left: 3rem;
  border-radius: 45px;
  margin-bottom: var(--dl-space-space-twounits);
  padding-right: 3rem;
  padding-bottom: 1.5rem;
  text-decoration: none;
  background-color: var(--dl-color-gray-white);
}
.profile-and-resume-banner-button:hover {
  border-color: rgba(41, 20, 119, 0.9);
  background-color: rgba(41, 20, 119, 0.9);
}
.profile-and-resume-feature-card1 {
  width: 100%;
  height: 193px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: flex-start;
  background-color: var(--dl-color-gray-white);
}
.profile-and-resume-image2 {
  width: 178px;
  height: 114px;
  margin-top: 0px;
  object-fit: cover;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  border-radius: 5%;
  margin-bottom: 0px;
}
.profile-and-resume-container04 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.profile-and-resume-text023 {
  font-family: "Raleway";
  font-weight: 600;
  line-height: 28px;
}
.profile-and-resume-text024 {
  color: var(--dl-color-gray-800);
  line-height: 1.6;
}
.profile-and-resume-blog {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.profile-and-resume-container05 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}
.profile-and-resume-blog-post-card {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: row;
  justify-content: center;
}
.profile-and-resume-image3 {
  width: 500px;
  height: 373px;
  align-self: center;
  object-fit: cover;
  border-radius: 25px;
}
.profile-and-resume-container06 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.profile-and-resume-text027 {
  color: var(--dl-color-gray-500);
  font-style: normal;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  text-transform: uppercase;
}
.profile-and-resume-text028 {
  margin-bottom: var(--dl-space-space-threeunits);
}
.profile-and-resume-container07 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
  justify-content: flex-start;
}
.profile-and-resume-text029 {
  color: var(--dl-color-gray-500);
  margin-bottom: var(--dl-space-space-halfunit);
}
.profile-and-resume-text031 {
  color: var(--dl-color-gray-500);
}
.profile-and-resume-text034 {
  color: var(--dl-color-gray-500);
}
.profile-and-resume-text037 {
  color: var(--dl-color-gray-black);
  display: none;
  font-weight: 500;
}
.profile-and-resume-text038 {
  color: var(--dl-color-gray-500);
  font-weight: 700;
}
.profile-and-resume-container08 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}
.profile-and-resume-blog-post-card1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: row;
  justify-content: center;
}
.profile-and-resume-image4 {
  width: 500px;
  height: auto;
  align-self: center;
  object-fit: cover;
}
.profile-and-resume-container09 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.profile-and-resume-text039 {
  color: var(--dl-color-gray-500);
  font-style: normal;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  text-transform: uppercase;
}
.profile-and-resume-text040 {
  margin-bottom: var(--dl-space-space-threeunits);
}
.profile-and-resume-container10 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
  justify-content: flex-start;
}
.profile-and-resume-text041 {
  color: var(--dl-color-gray-500);
  margin-bottom: var(--dl-space-space-halfunit);
}
.profile-and-resume-text043 {
  color: var(--dl-color-gray-500);
}
.profile-and-resume-text046 {
  color: var(--dl-color-gray-500);
}
.profile-and-resume-text049 {
  color: var(--dl-color-gray-500);
}
.profile-and-resume-text052 {
  color: var(--dl-color-gray-500);
}
.profile-and-resume-text054 {
  color: var(--dl-color-gray-black);
  display: none;
  font-weight: 500;
}
.profile-and-resume-text055 {
  color: var(--dl-color-gray-500);
  font-weight: 400;
}
.profile-and-resume-text057 {
  font-weight: 700;
}
.profile-and-resume-text058 {
  font-weight: 700;
}
.profile-and-resume-text059 {
  font-weight: 700;
}
.profile-and-resume-container11 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}
.profile-and-resume-blog-post-card2 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: row;
  justify-content: center;
}
.profile-and-resume-image5 {
  width: 500px;
  height: auto;
  align-self: center;
  object-fit: cover;
}
.profile-and-resume-container12 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.profile-and-resume-text061 {
  color: var(--dl-color-gray-500);
  font-style: normal;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  text-transform: uppercase;
}
.profile-and-resume-text062 {
  margin-bottom: var(--dl-space-space-threeunits);
}
.profile-and-resume-container13 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
  justify-content: flex-start;
}
.profile-and-resume-text063 {
  color: var(--dl-color-gray-500);
  margin-bottom: var(--dl-space-space-halfunit);
}
.profile-and-resume-text065 {
  color: var(--dl-color-gray-500);
}
.profile-and-resume-text068 {
  color: var(--dl-color-gray-black);
  display: none;
  font-weight: 500;
}
.profile-and-resume-text069 {
  color: var(--dl-color-gray-500);
  font-weight: 400;
}
.profile-and-resume-text071 {
  font-weight: 700;
}
.profile-and-resume-text072 {
  font-weight: 700;
}
.profile-and-resume-text073 {
  font-weight: 700;
}
.profile-and-resume-container14 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}
.profile-and-resume-blog-post-card3 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: row;
  justify-content: center;
}
.profile-and-resume-image6 {
  width: 500px;
  align-self: center;
  object-fit: cover;
}
.profile-and-resume-container15 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.profile-and-resume-text074 {
  color: var(--dl-color-gray-500);
  font-style: normal;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  text-transform: uppercase;
}
.profile-and-resume-text075 {
  margin-bottom: var(--dl-space-space-threeunits);
}
.profile-and-resume-container16 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
  justify-content: flex-start;
}
.profile-and-resume-text076 {
  color: var(--dl-color-gray-500);
  margin-bottom: var(--dl-space-space-halfunit);
}
.profile-and-resume-text078 {
  color: var(--dl-color-gray-500);
}
.profile-and-resume-text081 {
  color: var(--dl-color-gray-500);
}
.profile-and-resume-text084 {
  color: var(--dl-color-gray-500);
}
.profile-and-resume-text086 {
  color: var(--dl-color-gray-black);
  display: none;
  font-weight: 500;
}
.profile-and-resume-text087 {
  color: var(--dl-color-gray-500);
  font-weight: 400;
}
.profile-and-resume-text089 {
  font-weight: 700;
}
.profile-and-resume-text090 {
  font-weight: 700;
}
.profile-and-resume-container17 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}
.profile-and-resume-blog-post-card4 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: row;
  justify-content: center;
}
.profile-and-resume-image7 {
  width: 500px;
  padding: var(--dl-space-space-threeunits);
  align-self: center;
  object-fit: cover;
}
.profile-and-resume-container18 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.profile-and-resume-text091 {
  color: var(--dl-color-gray-500);
  font-style: normal;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  text-transform: uppercase;
}
.profile-and-resume-text092 {
  margin-bottom: var(--dl-space-space-threeunits);
}
.profile-and-resume-container19 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
  justify-content: flex-start;
}
.profile-and-resume-text093 {
  color: var(--dl-color-gray-500);
  margin-bottom: var(--dl-space-space-halfunit);
}
.profile-and-resume-text095 {
  color: var(--dl-color-gray-500);
}
.profile-and-resume-text098 {
  color: var(--dl-color-gray-500);
}
.profile-and-resume-text101 {
  color: var(--dl-color-gray-500);
}
.profile-and-resume-text103 {
  color: var(--dl-color-gray-black);
  display: none;
  font-weight: 500;
}
.profile-and-resume-text104 {
  color: var(--dl-color-gray-500);
  font-weight: 400;
}
.profile-and-resume-text107 {
  font-weight: 700;
}
.profile-and-resume-container20 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}
.profile-and-resume-blog-post-card5 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  flex-direction: row;
  justify-content: center;
}
.profile-and-resume-image8 {
  width: auto;
  height: auto;
  margin: var(--dl-space-space-unit);
  align-self: center;
  object-fit: cover;
}
.profile-and-resume-container21 {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.profile-and-resume-text108 {
  color: var(--dl-color-gray-500);
  font-style: normal;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  text-transform: uppercase;
}
.profile-and-resume-text109 {
  margin-bottom: var(--dl-space-space-threeunits);
}
.profile-and-resume-container22 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
  justify-content: flex-start;
}
.profile-and-resume-text110 {
  color: var(--dl-color-gray-500);
  margin-bottom: var(--dl-space-space-halfunit);
}
.profile-and-resume-text111 {
  color: var(--dl-color-gray-black);
  display: none;
  font-weight: 500;
}
.profile-and-resume-text112 {
  color: var(--dl-color-gray-500);
  font-weight: 400;
}
@media(max-width: 991px) {
  .profile-and-resume-pricing-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .profile-and-resume-container02 {
    align-items: center;
    flex-direction: column;
  }
  .profile-and-resume-banner-sub-heading {
    max-width: 100%;
  }
  .profile-and-resume-image3 {
    width: 50%;
  }
  .profile-and-resume-image4 {
    width: 50%;
  }
  .profile-and-resume-image5 {
    width: 50%;
  }
  .profile-and-resume-image6 {
    width: 50%;
  }
  .profile-and-resume-image7 {
    width: 50%;
  }
  .profile-and-resume-image8 {
    width: 50%;
  }
}
@media(max-width: 767px) {
  .profile-and-resume-pricing {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .profile-and-resume-pricing-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .profile-and-resume-container02 {
    align-items: center;
    flex-direction: column;
  }
  .profile-and-resume-feature-card {
    flex-direction: row;
  }
  .profile-and-resume-container03 {
    flex-direction: column;
  }
  .profile-and-resume-profesional-background {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .profile-and-resume-banner-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .profile-and-resume-feature-card1 {
    flex-direction: row;
  }
  .profile-and-resume-container04 {
    flex-direction: column;
  }
  .profile-and-resume-blog {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .profile-and-resume-blog-post-card {
    flex-direction: column;
    justify-content: space-between;
  }
  .profile-and-resume-image3 {
    width: 100%;
  }
  .profile-and-resume-container06 {
    width: 100%;
  }
  .profile-and-resume-text027 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .profile-and-resume-text028 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .profile-and-resume-container07 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .profile-and-resume-blog-post-card1 {
    flex-direction: column;
    justify-content: space-between;
  }
  .profile-and-resume-image4 {
    width: 100%;
  }
  .profile-and-resume-container09 {
    width: 100%;
  }
  .profile-and-resume-text039 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .profile-and-resume-text040 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .profile-and-resume-container10 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .profile-and-resume-blog-post-card2 {
    flex-direction: column;
    justify-content: space-between;
  }
  .profile-and-resume-image5 {
    width: 100%;
  }
  .profile-and-resume-container12 {
    width: 100%;
  }
  .profile-and-resume-text061 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .profile-and-resume-text062 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .profile-and-resume-container13 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .profile-and-resume-blog-post-card3 {
    flex-direction: column;
    justify-content: space-between;
  }
  .profile-and-resume-image6 {
    width: 100%;
  }
  .profile-and-resume-container15 {
    width: 100%;
  }
  .profile-and-resume-text074 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .profile-and-resume-text075 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .profile-and-resume-container16 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .profile-and-resume-blog-post-card4 {
    flex-direction: column;
    justify-content: space-between;
  }
  .profile-and-resume-image7 {
    width: 100%;
  }
  .profile-and-resume-container18 {
    width: 100%;
  }
  .profile-and-resume-text091 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .profile-and-resume-text092 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .profile-and-resume-container19 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .profile-and-resume-blog-post-card5 {
    flex-direction: column;
    justify-content: space-between;
  }
  .profile-and-resume-image8 {
    width: 100%;
  }
  .profile-and-resume-container21 {
    width: 100%;
  }
  .profile-and-resume-text108 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .profile-and-resume-text109 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .profile-and-resume-container22 {
    margin-bottom: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .profile-and-resume-container03 {
    flex-direction: column;
  }
  .profile-and-resume-profesional-background {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .profile-and-resume-container04 {
    flex-direction: column;
  }
  .profile-and-resume-blog {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .profile-and-resume-text037 {
    width: auto;
  }
  .profile-and-resume-text054 {
    width: auto;
  }
  .profile-and-resume-text068 {
    width: auto;
  }
  .profile-and-resume-text086 {
    width: auto;
  }
  .profile-and-resume-text103 {
    width: auto;
  }
  .profile-and-resume-text111 {
    width: auto;
  }
}
