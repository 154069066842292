.small-spacer-container {
  width: 100%;
  height: var(--dl-size-size-small);
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}



.small-spacer-root-class-name3 {
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
}






